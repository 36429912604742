import _compact from "lodash/compact";
import _last from "lodash/last";
import axios from 'axios/index';
import { GENERIC_ERROR_MESSAGE, getErrorMessage } from '../formActionUtils';
export var submitForm = function submitForm(_ref) {
  var email = _ref.email;
  var from = "".concat(window.location.pathname).concat(window.location.search);
  var data = {
    email: email,
    from: from
  };

  var lastPathname = _last(_compact(window.location.pathname.split('/')));

  var fromBlogPostUid = lastPathname && lastPathname === 'the-work' ? '' : lastPathname;
  return axios.post('/the-work/subscribe', data).then(function () {
    return window.location = "/subscribe-success?from=".concat(fromBlogPostUid);
  })["catch"](function (error) {
    var message = getErrorMessage(error);

    if (message) {
      throw new Error(message);
    }

    throw new Error(GENERIC_ERROR_MESSAGE);
  });
};