// Utils
import rafThrottle from '../../../util/rafThrottle';
var blogLandingPageNode = document.getElementById('blog-landing-page');
var SHRINK_THRESHOLD = blogLandingPageNode ? 40 : 200;
export default (function () {
  var shrunk = false;
  var subscriptionSectionNode = document.getElementById('blog-header-floating');

  var onScroll = function onScroll() {
    var shouldShrink = window.scrollY > SHRINK_THRESHOLD;
    if (shouldShrink === shrunk) return;
    shrunk = shouldShrink;

    if (shouldShrink) {
      subscriptionSectionNode.classList.add('shrink');
    } else {
      subscriptionSectionNode.classList.remove('shrink');
    }
  };

  var debouncedOnScroll = rafThrottle(onScroll);
  document.addEventListener('scroll', debouncedOnScroll);
});