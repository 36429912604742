import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _get from "lodash/get";
import { FORM_ERROR } from 'final-form';
export var RESPONSE_ERROR = {
  VALIDATION_FAILURE: 'INVALID',
  LOCKED: 'LOCKED',
  NOT_ADMIN: 'NOT_ADMIN',
  NOT_SUBSCRIBED: 'NOT_SUBSCRIBED',
  BAD_REQUEST: 'BAD_REQUEST',
  FORBIDDEN: 'FORBIDDEN'
};

var isNetworkError = function isNetworkError(errorDetails) {
  return (errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.message) === 'Network Error';
};

export default (function (err) {
  var _err$response;

  if (!err.response) {
    return _defineProperty({}, FORM_ERROR, {
      message: _get(err, 'message') || 'An unknown error occurred. Please try again or contact support.',
      isNetworkError: isNetworkError(err)
    });
  }

  var error = _get(err, 'response.data.error');

  var isClientValidationError = error && error.code === RESPONSE_ERROR.VALIDATION_FAILURE; // On validation errors respond with a structure that the form can react to
  // But don't show any primary error message to the user

  if (isClientValidationError) {
    return error.details;
  }

  return _defineProperty({}, FORM_ERROR, {
    message: _get(error, 'message') || 'Something went wrong, please try again',
    status: (_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status,
    isNetworkError: isNetworkError(err)
  });
});