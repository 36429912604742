import _throttle from "lodash/fp/throttle";
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

var getScrollTop = function getScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop;
};

var getTop = function getTop(element) {
  if (!element) return 0;
  var rect = element.getBoundingClientRect();
  if (!rect) return 0;
  return rect.top + getScrollTop();
};

var findSiblingNavItems = function findSiblingNavItems(navItems) {
  navItems.forEach(function (navItem, index) {
    if (!navItem.classList.contains('step-major')) return;
    var siblingItems = [navItem];
    var i = index + 1;
    var nextItem = navItems[i];

    while (nextItem && !nextItem.classList.contains('step-major')) {
      siblingItems.push(nextItem);
      i++;
      nextItem = navItems[i];
    }

    siblingItems.forEach(function (item) {
      item.siblingItems = siblingItems;
    });
  });
};

var isNavigating = false;

var attachHeaderNavigationScrollListener = function attachHeaderNavigationScrollListener(navigation) {
  var navItems = navigation.querySelectorAll('ul > li > a');
  navItems.forEach(function (navItem, index) {
    var stepId = navItem.dataset.stepId;
    var stepTitle = document.querySelector("#".concat(stepId));
    if (!stepTitle) return;
    navItem.addEventListener('click', function (event) {
      event.preventDefault();
      var top = getTop(stepTitle);
      window.scrollTo({
        top: top - 110,
        behavior: 'smooth'
      });
      isNavigating = true;
      setTimeout(function () {
        isNavigating = false;
      }, 700);
    });
  });
};

var attachNavigationScrollListener = function attachNavigationScrollListener(navigation) {
  var navItems = navigation.querySelectorAll('ul > li > a');
  var navScrollPoints = [];
  findSiblingNavItems(navItems);

  var removeAllActive = function removeAllActive() {
    navItems.forEach(function (navItem) {
      navItem.classList.remove('active');
      navItem.classList.remove('visible');
    });
  };

  var setActive = function setActive(navItem) {
    if (!navItem) return;
    removeAllActive();
    navItem.classList.add('active');
    navItem.siblingItems && navItem.siblingItems.forEach(function (sibling) {
      sibling.classList.add('visible');
    });
  };

  navItems.forEach(function (navItem, index) {
    var stepId = navItem.dataset.stepId;
    var stepTitle = document.querySelector("#".concat(stepId));
    if (!stepTitle) return;
    navScrollPoints.push({
      navItem: navItem,
      stepTitle: stepTitle
    });
    navItem.addEventListener('click', function (event) {
      event.preventDefault();
      var top = getTop(stepTitle);
      window.scrollTo({
        top: top - 110,
        behavior: 'smooth'
      });
      isNavigating = true;
      setActive(navItem);
      setTimeout(function () {
        isNavigating = false;
      }, 700);
    });
  });

  var checkScrollPosition = function checkScrollPosition() {
    if (isNavigating) return;
    var scrollTop = getScrollTop() + 150;
    var currentPoint = navScrollPoints.find(function (_ref, index) {
      var stepTitle = _ref.stepTitle,
          navItem = _ref.navItem;
      var nextScrollPoint = navScrollPoints[index + 1];
      var currentBreakpoint = index === 0 ? -1000 : getTop(stepTitle);
      var nextBreakpoint = nextScrollPoint && getTop(nextScrollPoint.stepTitle) || Infinity;

      if (scrollTop > currentBreakpoint && scrollTop < nextBreakpoint) {
        return true;
      }

      return false;
    });

    if (currentPoint) {
      setActive(currentPoint.navItem);
    }
  };

  var throttledCheckScrollPosition = _throttle(250, checkScrollPosition);

  window.addEventListener('scroll', throttledCheckScrollPosition);
};

var setupGuideTableOfContentsNavigation = function setupGuideTableOfContentsNavigation() {
  var navigation = document.querySelector('.Guide2020TableOfContents .guide-toc-navigation, .MegaGuideTableOfContents .guide-toc-navigation');
  if (navigation) attachNavigationScrollListener(navigation);
  var headerNavigation = document.querySelector('.MegaGuidePageHeader .navigation-table');
  if (headerNavigation) attachHeaderNavigationScrollListener(headerNavigation);
};

export default setupGuideTableOfContentsNavigation;