import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var getBoxPositionOnWindowCenter = function getBoxPositionOnWindowCenter(width, height) {
  return {
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2
  };
};

var SHARE_WINDOW_WIDTH = 800;
var SHARE_WINDOW_HEIGHT = 600;

var setupSocialShareButtons = function setupSocialShareButtons() {
  var shareButtonContainer = document.querySelector('.SocialShareButtons');
  if (!shareButtonContainer) return;
  var shareButtons = shareButtonContainer.querySelectorAll('.ShareButton');
  shareButtons.forEach(function (shareButton) {
    shareButton.addEventListener('click', function (event) {
      event.preventDefault();

      var options = _objectSpread(_objectSpread({}, getBoxPositionOnWindowCenter(SHARE_WINDOW_WIDTH, SHARE_WINDOW_HEIGHT)), {}, {
        width: SHARE_WINDOW_WIDTH,
        height: SHARE_WINDOW_HEIGHT,
        location: 'no',
        toolbar: 'no',
        status: 'no',
        directories: 'no',
        menubar: 'no',
        scrollbars: 'yes',
        resizable: 'no',
        centerscreen: 'yes',
        chrome: 'yes'
      });

      var optionsString = Object.keys(options).map(function (key) {
        return "".concat(key, "=").concat(options[key]);
      }).join(',');
      window.open(shareButton.href, null, optionsString);
    });
  });
};

export default setupSocialShareButtons;