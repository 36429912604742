import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _isEmpty from "lodash/isEmpty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import fieldValidationService from './fieldValidationService';
export var validateField = function validateField(field) {
  return function (value, _, meta) {
    var fieldError = fieldValidationService(value, field);
    if (_isEmpty(meta)) return fieldError; // If meta exists (field-level validation), only show error based on the error configuration:
    // - error.hideBeforeSubmit - Used to only show error after a submission failed
    // - error.hideIfTouched - Used to only show error when field is pristine, and hide error afterwards

    var shouldShowError = fieldError && (!fieldError.hideBeforeSubmit || fieldError.hideBeforeSubmit && meta.submitFailed) && (!fieldError.hideIfTouched || fieldError.hideIfTouched && !meta.touched);
    return shouldShowError ? fieldError : undefined;
  };
}; // Used to validate multiple fields during a form submission

export var validateFields = function validateFields(fields) {
  return function (values) {
    return fields.reduce(function (acc, field) {
      var value = values[field.name];
      var error = validateField(field)(value);
      if (!error) return acc;
      return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, field.name, error));
    }, {});
  };
};