import Swipe from 'swipejs';
export default (function (caroursel) {
  var swipeRoot = caroursel.querySelector('.swipe');
  var swipeMarkers = Array.from(caroursel.querySelectorAll('.swipe-markers > div'));

  var setSwipeMarkerActive = function setSwipeMarkerActive(index) {
    var activeMarker = swipeMarkers[index];
    if (!activeMarker) return;
    swipeMarkers.forEach(function (marker) {
      return marker.classList.remove('active');
    });
    activeMarker.classList.add('active');
  };

  setSwipeMarkerActive(0);
  return new Swipe(swipeRoot, {
    callback: setSwipeMarkerActive,
    draggable: true
  });
});