export default (function (caroursel) {
  var slides = Array.from(caroursel.querySelectorAll('.slides .slide'));
  var navButtons = Array.from(caroursel.querySelectorAll('a.segment-button'));
  if (!slides || !slides.length) return;
  var currentSlide = slides[0].dataset.slideId;

  var showSlide = function showSlide(slideToShow) {
    if (!slideToShow) return;
    if (currentSlide === slideToShow) return;
    var buttonToSelect = navButtons.find(function (button) {
      return button.dataset.slideId === slideToShow.dataset.slideId;
    });
    slides.forEach(function (slide) {
      return slide.classList.remove('visible');
    });
    navButtons.forEach(function (button) {
      return button.classList.remove('selected');
    });
    currentSlide = slideToShow;
    setTimeout(function () {
      slideToShow.classList.add('visible');
      buttonToSelect.classList.add('selected');
    }, 350);
  };

  var showSlideWithId = function showSlideWithId(slideId) {
    return showSlide(slides.find(function (slide) {
      return slide.dataset.slideId === slideId;
    }));
  };

  navButtons.forEach(function (button) {
    button.addEventListener('click', function (event) {
      event.preventDefault();
      showSlideWithId(button.dataset.slideId);
    });
  });
});