import _throttle from "lodash/fp/throttle";

// SHRINK ON SCROLL
var setupHeaderShrinkOnScroll = function setupHeaderShrinkOnScroll(header) {
  var scrollMargin = header.dataset.onScrollMargin || 0;
  var scrollClasses = header.dataset.onScrollClass.split(' ');

  var checkScrollPosition = function checkScrollPosition() {
    if (window.innerWidth < 767) return;

    if (window.pageYOffset > scrollMargin) {
      scrollClasses.forEach(function (scrollClass) {
        header.classList.add(scrollClass);
      });
    } else {
      scrollClasses.forEach(function (scrollClass) {
        header.classList.remove(scrollClass);
      });
    }
  };

  var throttledCheckScrollPosition = _throttle(100, checkScrollPosition);

  window.addEventListener('scroll', function (event) {
    return throttledCheckScrollPosition();
  });
  checkScrollPosition();
}; // MEGA MENU


var setupHeaderMegaMenu = function setupHeaderMegaMenu(megaMenu) {
  var button = megaMenu.querySelector('.header-menu-button');
  var menuPanel = megaMenu.querySelector('.header-menu-panel');
  var menuVisible = false;

  var hideMenu = function hideMenu() {
    if (!menuVisible) return;
    menuVisible = false;
    menuPanel.classList.remove('transition');
    setTimeout(function () {
      menuPanel.classList.remove('visible');
    }, 300);
    document.removeEventListener('click', hideMenu);
  };

  var showMenu = function showMenu() {
    if (menuVisible) return;
    menuVisible = true;
    menuPanel.classList.add('visible');
    setTimeout(function () {
      menuPanel.classList.add('transition');
    }, 0);
    document.addEventListener('click', hideMenu);
  };

  menuPanel.addEventListener('click', function (event) {
    return event.stopPropagation();
  });
  button.addEventListener('click', function (event) {
    event.preventDefault();
    event.stopPropagation();

    if (menuVisible) {
      hideMenu();
    } else {
      showMenu();
    }
  });
};

var setupMobileNav = function setupMobileNav(header) {
  var navButton = header.querySelector('.mobile-nav-reveal');
  var closeButton = header.querySelector('.mobile-nav-close');
  var nav = header.querySelector('.nav');
  if (!navButton || !nav) return;

  var toggleNav = function toggleNav(event) {
    event.preventDefault();
    event.stopPropagation();
    nav.classList.toggle('visible-mobile');
  };

  navButton.addEventListener('click', toggleNav);
  closeButton.addEventListener('click', toggleNav);
};

export default (function (header) {
  if (header.dataset.onScrollClass) {
    setupHeaderShrinkOnScroll(header);
  }

  var megaMenu = header.querySelector('#mega-menu');
  if (megaMenu) setupHeaderMegaMenu(megaMenu);
  setupMobileNav(header);
});