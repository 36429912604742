// Polyfill
import rafPolyfill from './rafPolyfill'; // Request animation frame polyfill

rafPolyfill();
/**
 * Throttles calls to a function to ensure they only happen on a request animation frame.
 */

export default (function (fn) {
  var requestId;

  var throttled = function throttled() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var latestArgs = args; // If we have already requested an animation frame, return

    if (requestId) return; // Wait an animation frame

    requestId = requestAnimationFrame(function () {
      requestId = null;
      fn.apply(void 0, latestArgs);
    });
  };

  throttled.cancel = function () {
    if (!requestId) return;
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
});