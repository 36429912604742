import _reduce from "lodash/reduce";
// Utils
import { getParams, getQuery } from '../../common/util/urlUtil';
export var getQueryUtmParams = function getQueryUtmParams(location) {
  var query = getQuery(location);
  var queryParams = getParams(query);
  return _reduce(queryParams, function (utmParams, value, key) {
    if (key === 'gclid') {
      utmParams['utm_medium'] = 'cpc';
      utmParams['utm_source'] = 'google';
      return utmParams;
    }

    if (key.indexOf('utm_') !== 0) return utmParams;
    utmParams[key] = value;
    return utmParams;
  }, {});
};