import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { FAILURE_TYPES, FAILURE_NAMES } from '../validationConstants';
var ERROR_OBJECT = {
  name: FAILURE_NAMES.required,
  type: FAILURE_TYPES.ERROR,
  hideBeforeSubmit: true,
  hideIfTouched: true
};
/**
 * Returns an error object if a field's config states that the field is required, but the field does not have a value.
 * Otherwise, returns null.
 */

export default (function (fieldValue, validationConfig) {
  return validationConfig.required && !fieldValue ? _objectSpread(_objectSpread({}, ERROR_OBJECT), {}, {
    fieldName: validationConfig.name
  }) : null;
});