import { getCachedAuthStatus, AUTH_STATUSES } from './queryAppAuthState';
export default (function () {
  // attach register form to site
  var registrationModalRoot = document.querySelector('#register-form-modal');

  if (registrationModalRoot) {
    var registrationModalCloseTrigger = document.querySelector('#register-form-modal .modal-close-trigger');
    registrationModalCloseTrigger.addEventListener('click', function () {
      document.body.style.overflow = 'auto';
      registrationModalRoot.classList.add('fade-out');
      registrationModalRoot.classList.remove('show');
      setTimeout(function () {
        registrationModalRoot.classList.remove('visible');
        registrationModalRoot.classList.remove('fade-out');
      }, 600);
    });
  }

  var registrationModalTrigger = document.querySelectorAll('.register-overlay-trigger');
  registrationModalTrigger.forEach(function (button) {
    return button.addEventListener('click', function (event) {
      if (getCachedAuthStatus() === AUTH_STATUSES.LOGGED_IN) {
        event.preventDefault();
        var loggedInHref = button.dataset.loggedInHref;

        if (loggedInHref) {
          window.location = loggedInHref;
          return;
        }
      }

      if (!button.dataset.triggerOverlay) return;
      if (!registrationModalRoot) return;
      event.preventDefault();
      document.body.style.overflow = 'hidden';
      registrationModalRoot.classList.add('visible');
      setTimeout(function () {
        registrationModalRoot.classList.add('show');
        registrationModalRoot.classList.add('fade-in');
      }, 1);
      setTimeout(function () {
        return registrationModalRoot.classList.remove('fade-in');
      }, 600);
    });
  });
});