// Utils
import { getParams } from '../../../../util/urlUtil';
var ARRIVAL_AVENUE_STORAGE_KEY = 'arrival_avenue';
export var getPersistedSessionArrivalAvenue = function getPersistedSessionArrivalAvenue() {
  if (!window.sessionStorage) return 'unknown';
  return window.sessionStorage.getItem(ARRIVAL_AVENUE_STORAGE_KEY);
};

var setPersistedSessionArrivalAvenue = function setPersistedSessionArrivalAvenue(arrivalAvenue) {
  if (!window.sessionStorage) return;
  return window.sessionStorage.setItem(ARRIVAL_AVENUE_STORAGE_KEY, arrivalAvenue);
};

var determineSessionArrivalAvenue = function determineSessionArrivalAvenue() {
  var params = getParams(window.location.search); // Invite code

  if (params.invite) return 'board invite'; // Referral code

  if (params.referrer) return 'referral';
  var path = window.location.pathname; // Home page

  if (path === '/') return 'home page'; // Sub path

  return path;
};

export var persistSessionArrivalAvenue = function persistSessionArrivalAvenue() {
  var persistedSessionArrivalAvenue = getPersistedSessionArrivalAvenue(); // If we've already persisted the session arrival location - don't persist again

  if (persistedSessionArrivalAvenue) return;
  var arrivalAvenue = determineSessionArrivalAvenue();
  setPersistedSessionArrivalAvenue(arrivalAvenue);
};