import omit from 'lodash/fp/omit';
import { getParams, createQueryString } from '../common/util/urlUtil';
var omitParams = omit(['from', 'query', 'page', 'tag']);

var maintainLinkQueryParams = function maintainLinkQueryParams() {
  // add refferer, invite, etc query strings to internal links
  var params = omitParams(getParams(window.location.search)); // strip "?" from query

  var query = createQueryString(params).substr(1);
  if (!query) return;
  var links = document.getElementsByTagName('a');
  var localOrigin = window.location.origin;

  for (var i = 0; i < links.length; i++) {
    var link = links[i]; // if link is an external location, don't add query string

    if (link.href.indexOf(localOrigin) === -1) continue;
    var queryMark = link.href.indexOf('?') === -1 ? '?' : '&';
    link.href = "".concat(link.href).concat(queryMark).concat(query);
  }
};

export default maintainLinkQueryParams;