import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _last from "lodash/last";
import _keys from "lodash/keys";
import _head from "lodash/head";
var DOMAIN_REGEX = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im;
export var getDomain = function getDomain(url) {
  var domainMatchResult = url.match(DOMAIN_REGEX);
  return domainMatchResult && domainMatchResult[1];
};
export var getQuery = function getQuery(location) {
  return location ? location.search : '';
};
export var getParams = function getParams(query) {
  if (!query) return {};
  var queryString = /^[?#]/.test(query) ? query.slice(1) : query;
  if (!queryString) return {};
  return queryString.split('&').reduce(function (params, param) {
    var _param$split = param.split('='),
        _param$split2 = _slicedToArray(_param$split, 2),
        key = _param$split2[0],
        value = _param$split2[1];

    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    return params;
  }, {});
};

var stringValue = function stringValue(value) {
  if (_typeof(value) === 'object' && Array.isArray(value)) return value.join(',');
  if (_typeof(value) === 'object') return JSON.stringify(value);
  return value ? value.toString() : '';
}; // takes an object map and returns a query string
// @input: { filter_ids=[abc,123], filter_after: 1245 }
// @output: 'filter_ids=abc,123&filter_after=1234'


export var createQueryString = function createQueryString(queryObject) {
  if (!queryObject) return '';
  var allKeys = Object.keys(queryObject).filter(function (key) {
    return !!queryObject[key];
  });
  if (!allKeys.length) return '';
  var query = allKeys.map(function (key) {
    return "".concat(key, "=").concat(stringValue(queryObject[key]));
  }).join('&');
  return "?".concat(query);
};
export var createImageUrl = function createImageUrl(imageSrc, params) {
  var imageUrl;

  try {
    imageUrl = new URL(imageSrc);
  } catch (err) {
    return undefined;
  }

  _keys(params).forEach(function (key) {
    var value = params[key];

    if (value === undefined) {
      imageUrl.searchParams["delete"](key);
    } else {
      imageUrl.searchParams.set(key, value);
    }
  });

  return imageUrl.toString();
}; // Transform image URL to be served from Milanote CDN (images.milanote.com) instead of Prismic (images.prismic.io)

export var transformImageUrlToMilanoteCdn = function transformImageUrlToMilanoteCdn(imageUrl) {
  if (!imageUrl) return imageUrl;
  return imageUrl.replace('images.prismic.io', 'images.milanote.com');
};
export var stripQueryString = function stripQueryString(location) {
  return _head(location.split(/[?#]/));
};
export var getQueryString = function getQueryString(location) {
  return /[?#]/.test(location) ? _last(location.split(/[?#]/)) : '';
};