import { DATALAYER_EVENT } from './analytics/dataLayerConstants';
import { sendCtaClickEvent, sendEvent, extractLinkClickEventData } from './analytics/dataLayerService';

var processLinkClick = function processLinkClick(link) {
  return function (event) {
    if (!link) return;

    var _extractLinkClickEven = extractLinkClickEventData(link),
        eventName = _extractLinkClickEven.eventName,
        eventData = _extractLinkClickEven.eventData;

    switch (eventName) {
      case DATALAYER_EVENT.CTA_CLICK:
        sendCtaClickEvent(eventData);
        break;

      default:
        sendEvent(eventName, eventData);
    }
  };
};

var setupLinkGAClickTracking = function setupLinkGAClickTracking() {
  var trackLinks = document.querySelectorAll('a[data-track-click-event]');
  trackLinks.forEach(function (link) {
    link.addEventListener('click', processLinkClick(link));
  });
};

export default setupLinkGAClickTracking;