module.exports = {
  env: process.env.NODE_ENV || 'production',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8080,
  prismicAccessToken: process.env.PRISMIC_TOKEN,
  prismicApiEndpoint: 'https://milanote.cdn.prismic.io/api',
  staticPath: process.env.STATIC_PATH || 'http://localhost:8081',
  appUrl: process.env.APP_URL || 'http://localhost:3000',
  publicSiteUrl: process.env.PUBLIC_SITE_URL || (process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://milanote.com'),
  cookieDomain: process.env.COOKIE_DOMAIN || 'localhost',
  s3ReleaseBucketUrl: process.env.S3_RELEASE_BUCKET_URL || 'https://releases.milanote.com',
  analytics: {
    enabled: process.env.NODE_ENV === 'production' || process.env.ANALYTICS_ENABLED === 'true',
    googleAnalyticsCode: process.env.GA_CODE || 'UA-79433617-1',
    googleTagManagerCode: process.env.GTM_CODE || 'GTM-54XWBQ4',
    googleTagManagerContainerInfo: process.env.NODE_ENV === 'production' ? '' : '&gtm_auth=TZOqGV9YxtmVrxJY98NSVA&gtm_preview=env-89&gtm_cookies_win=x',
    token: process.env.INTERCOM_TOKEN || 'dG9rOmE0ZjAwNDQ5XzRiYTlfNDk1Zl85MDdhX2FjYjAwZmQ3MDc5NzoxOjA='
  },
  appStore: {
    iOSAppId: 1433852790
  }
};