import get from 'lodash/fp/get';
export var GENERIC_ERROR_MESSAGE = 'Sorry, something went wrong';
export var getErrorDetail = get(['response', 'data', 'error', 'details']);
export var getErrorMessage = get(['response', 'data', 'error', 'message']);
export var normaliseFieldErrors = function normaliseFieldErrors(errorDetails) {
  return Object.keys(errorDetails).filter(function (key) {
    return !!errorDetails[key];
  }).reduce(function (acc, key) {
    acc[key] = errorDetails[key].message;
    return acc;
  }, {});
};