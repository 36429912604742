import _get from "lodash/fp/get";
// Lib
import { useMemo } from 'react';
import { getParams } from '../../../../../util/urlUtil';
var OAUTH_ERROR = {
  ACCESS_DENIED: 'access_denied',
  USER_CANCELLED_AUTHORIZE: 'user_cancelled_authorize',
  UNREGISTERED_USER: 'unregistered_user',
  CONFIRMATION_FAILURE: 'confirmation_failure'
};

var getErrorParam = _get(['error']);

var getEmailParam = _get(['email']);

var getProviderParam = _get(['provider']); // Access denied is user initiated, so we shouldn't show an error in that case


var getShowOauthError = function getShowOauthError(queryParams) {
  var error = getErrorParam(queryParams);
  return !!error && error !== OAUTH_ERROR.ACCESS_DENIED && error !== OAUTH_ERROR.USER_CANCELLED_AUTHORIZE;
};

var getSocialProvider = function getSocialProvider(queryParams) {
  return getProviderParam(queryParams) || 'Google';
};

var getOauthErrorMessage = function getOauthErrorMessage(queryParams) {
  var error = getErrorParam(queryParams);

  switch (error) {
    case OAUTH_ERROR.UNREGISTERED_USER:
      {
        var email = getEmailParam(queryParams);
        return email ? "The email ".concat(email, " is not registered") : 'That email is not registered';
      }

    case OAUTH_ERROR.CONFIRMATION_FAILURE:
      return "Failed to register the ".concat(getEmailParam(queryParams), " account");

    default:
      return "Something went wrong during ".concat(getSocialProvider(queryParams), " sign in");
  }
};

export default (function () {
  var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location;
  return useMemo(function () {
    var queryParams = getParams(location.search);
    var showOauthError = getShowOauthError(queryParams);
    var errorMessage = getOauthErrorMessage(queryParams);
    return showOauthError && errorMessage ? {
      message: errorMessage
    } : undefined;
  }, [location]);
});