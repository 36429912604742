import throttle from 'lodash/fp/throttle';
export default (function () {
  var fixedPanel = document.querySelector('.image-container');
  var floatingPanel = document.querySelector('.TemplateCTAPanel.floating');

  var hideCTAPanel = function hideCTAPanel() {
    if (!floatingPanel || floatingPanel.classList.contains('hidden')) return;
    floatingPanel.classList.add('hidden');
  };

  var showCTAPanel = function showCTAPanel() {
    if (!floatingPanel || !floatingPanel.classList.contains('hidden')) return;
    floatingPanel.classList.remove('hidden');
  };

  var checkScrollPosition = function checkScrollPosition() {
    if (fixedPanel.getBoundingClientRect().bottom < 0) {
      showCTAPanel();
    } else {
      hideCTAPanel();
    }
  };

  var throttledCheckScrollPosition = throttle(100, checkScrollPosition);
  window.addEventListener('scroll', function (event) {
    return throttledCheckScrollPosition();
  });
});