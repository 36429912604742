import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export var FIELD_TYPES = {
  text: 'text',
  email: 'email',
  password: 'password',
  checkbox: 'checkbox'
};
export var FAILURE_TYPES = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO'
};
export var FAILURE_NAMES = {
  required: 'required',
  email: 'email',
  date: 'date',
  select: 'select',
  password: 'password',
  maxlength: 'maxlength',
  url: 'url',
  elementId: 'elementId',
  object: 'object',
  objectId: 'objectId',
  number: 'number'
};
export var FIELDS = {
  givenName: {
    name: 'givenName',
    type: FIELD_TYPES.text,
    required: true,
    maxLength: 50
  },
  familyName: {
    name: 'familyName',
    type: FIELD_TYPES.text,
    required: true,
    maxLength: 50
  },
  email: {
    name: 'email',
    type: FIELD_TYPES.email,
    required: true,
    maxLength: 255
  },
  multiEmail: {
    name: 'multiEmail',
    type: FIELD_TYPES.multiEmail,
    required: true
  },
  password: {
    name: 'password',
    type: FIELD_TYPES.password,
    required: true,
    maxLength: 255
  },
  code: {
    name: 'code',
    type: FIELD_TYPES.text,
    required: true,
    maxLength: 40,
    minLength: 40
  }
};
FIELDS.loginPassword = _objectSpread(_objectSpread({}, FIELDS.password), {}, {
  disableTypeValidation: true
});
export var REGISTER_META_FIELDS = {
  inviteCode: {
    name: 'inviteCode',
    type: FIELD_TYPES.text
  },
  onboarding: {
    name: 'onboarding',
    type: FIELD_TYPES.text
  },
  initialEducation: {
    name: 'initialEducation',
    type: FIELD_TYPES.number
  },
  referrerCode: {
    name: 'referrerCode',
    type: FIELD_TYPES.text
  },
  marketingReferralCode: {
    name: 'marketingReferralCode',
    type: FIELD_TYPES.text
  },
  registrationAvenue: {
    name: 'registrationAvenue',
    type: FIELD_TYPES.text
  },
  registrationEntryPoint: {
    name: 'registrationEntryPoint',
    type: FIELD_TYPES.text
  },
  publicBoardId: {
    name: 'publicBoardId',
    type: FIELD_TYPES.text
  },
  permissionId: {
    name: 'permissionId',
    type: FIELD_TYPES.text
  },
  deviceId: {
    name: 'deviceId',
    type: FIELD_TYPES.text
  },
  guestAbGroup: {
    name: 'guestAbGroup',
    type: FIELD_TYPES.text
  },
  agreeToTerms: {
    name: 'agreeToTerms',
    type: FIELD_TYPES.checkbox,
    required: true
  },
  subscribeToProductUpdates: {
    name: 'subscribeToProductUpdates',
    type: FIELD_TYPES.checkbox
  }
}; // Registration page & route

export var REGISTER_FIELDS = _objectSpread(_objectSpread({}, REGISTER_META_FIELDS), {}, {
  givenName: FIELDS.givenName,
  familyName: FIELDS.familyName,
  email: FIELDS.email,
  password: FIELDS.password
});
export var SUBSCRIPTION_FIELDS = {
  email: FIELDS.email
};