import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _TYPE_VALIDATORS;

import emailValidator from './type/emailValidator';
import passwordValidator from './type/passwordValidator';
import { FIELD_TYPES } from '../validationConstants';
var TYPE_VALIDATORS = (_TYPE_VALIDATORS = {}, _defineProperty(_TYPE_VALIDATORS, FIELD_TYPES.email, emailValidator), _defineProperty(_TYPE_VALIDATORS, FIELD_TYPES.password, passwordValidator), _TYPE_VALIDATORS);
/**
 */

export default (function (fieldValue, validationConfig) {
  // If we specifically don't want to do type validation, then immediately return null
  if (validationConfig.disableTypeValidation) return null;
  var typeValidator = TYPE_VALIDATORS[validationConfig.type];
  return typeValidator ? typeValidator(fieldValue, validationConfig) : null;
});