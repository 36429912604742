import _defer from "lodash/fp/defer";
import { DATALAYER_FIELD } from './analytics/dataLayerConstants';
import { sendTooltipClickEvent } from './analytics/dataLayerService';

var getAbsolutePosition = function getAbsolutePosition(el) {
  var rect = el.getBoundingClientRect();
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    width: rect.width,
    height: rect.height
  };
};

var stopPropagationOnly = function stopPropagationOnly(event) {
  return event.stopPropagation();
};
/** Extract the step text based on the example button */


var extractStepText = function extractStepText(button) {
  if (!button) return;
  var parent = button.parentElement; // Check if Element.closest is supported

  if (parent.closest) {
    // identify parent container name
    try {
      var stepContainer = parent.closest('.guide-example-step');

      if (stepContainer) {
        var stepTextEl = stepContainer.querySelector('.step-text');
        if (stepTextEl) return stepTextEl.text || stepTextEl.textContent;
      }
    } catch (e) {// ignore action location if parent lookup fails / not supported
    }
  }
};

var setupGuidePopupPanels = function setupGuidePopupPanels() {
  var exampleButtons = document.querySelectorAll('.guide-example-show-tooltip-button');
  if (!exampleButtons.length) return;
  exampleButtons.forEach(function (button) {
    var tooltipId = button.dataset.tooltipId;
    if (!tooltipId) return;
    var tooltipPanel = document.querySelector("#".concat(tooltipId));
    var panelOpen = false;
    var panel;

    var destroyPanel = function destroyPanel() {
      if (!panel || !panelOpen) return;
      button.classList.remove('open');
      panelOpen = false;
      panel.className = 'GuideExampleTooltip';
      document.removeEventListener('click', destroyPanel, {
        once: true
      });
      setTimeout(function () {
        document.body.removeChild(panel);
      }, 150);
    };

    var createPanel = function createPanel() {
      if (panelOpen) return;
      panelOpen = true;
      button.classList.add('open');
      var buttonRect = getAbsolutePosition(button);
      var panelContent = tooltipPanel.cloneNode(true);
      panel = document.createElement('div');
      panel.className = 'GuideExampleTooltip';
      panel.appendChild(panelContent);
      var video = panel.querySelector('video.lazy');

      for (var source in video.children) {
        if (!video.children.hasOwnProperty(source)) continue;
        var videoSource = video.children[source];
        if (typeof videoSource.tagName !== 'string' || videoSource.tagName !== 'SOURCE') continue;
        videoSource.src = videoSource.dataset.src;
      }

      document.body.appendChild(panel);
      var panelRect = panel.getBoundingClientRect();

      if (window.innerWidth >= 767) {
        panel.style.top = "".concat(buttonRect.top - panelRect.height - 20, "px");
        panel.style.left = "".concat(buttonRect.left - panelRect.width / 2 + buttonRect.width / 2, "px");
      }

      panel.addEventListener('click', stopPropagationOnly);
      var closeButton = panel.querySelector('.mobile-close-button');
      closeButton && closeButton.addEventListener('click', destroyPanel, {
        once: true
      });

      _defer(function () {
        panel.className = 'GuideExampleTooltip visible';
        document.addEventListener('click', destroyPanel, {
          once: true
        });
      });
    };

    button.addEventListener('click', function (event) {
      event.preventDefault();

      if (panelOpen) {
        destroyPanel();
      } else {
        createPanel();
        sendTooltipClickEvent({
          actionText: button.textContent,
          actionSection: 'guide-example-step',
          actionCategory: DATALAYER_FIELD.ACTION_CATEGORY.GUIDE,
          actionName: extractStepText(button)
        });
      }
    });
  });
};

export default setupGuidePopupPanels;