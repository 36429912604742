import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { EMAIL_REGEX, FAILURE_TYPES, FAILURE_NAMES } from '../../validationConstants';
var EMAIL_ERROR_OBJECT = {
  name: FAILURE_NAMES.email,
  type: FAILURE_TYPES.ERROR,
  message: 'Not a valid email'
};

var getErrorObject = function getErrorObject(fieldValue, validationConfig) {
  return _objectSpread(_objectSpread({}, EMAIL_ERROR_OBJECT), {}, {
    value: fieldValue,
    hideBeforeSubmit: !!validationConfig.hideBeforeSubmit
  });
};

export default (function (fieldValue) {
  var validationConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!fieldValue) return null;
  if (!fieldValue.match(EMAIL_REGEX)) return getErrorObject(fieldValue, validationConfig);
  return null;
});