// Utils
import rafThrottle from '../../../util/rafThrottle'; // Show trigger

var REMAINING_SCROLL_TRIGGER_POINT = 1500;
export default (function () {
  var visible = false;
  var timeoutId = null;
  var subscriptionSectionNode = document.getElementById('blog-email-subscription');

  var showSubscriptionForm = function showSubscriptionForm() {
    visible = true;
    if (timeoutId) clearTimeout(timeoutId);

    if (subscriptionSectionNode.classList.contains('leave')) {
      subscriptionSectionNode.classList.remove('leave');
    } else {
      subscriptionSectionNode.classList.add('enter');
      subscriptionSectionNode.classList.add('visible');
      timeoutId = setTimeout(function () {
        subscriptionSectionNode.classList.remove('enter');
        timeoutId = null;
      }, 20);
    }
  };

  var hideSubscriptionForm = function hideSubscriptionForm() {
    visible = false;
    if (timeoutId) clearTimeout(timeoutId);

    if (subscriptionSectionNode.classList.contains('enter')) {
      subscriptionSectionNode.classList.remove('enter');
    } else {
      subscriptionSectionNode.classList.add('leave');
      timeoutId = setTimeout(function () {
        subscriptionSectionNode.classList.remove('leave');
        subscriptionSectionNode.classList.remove('visible');
        timeoutId = null;
      }, 200);
    }
  };

  var onScroll = function onScroll() {
    var currentScrollBottom = window.scrollY + window.innerHeight;
    var documentHeight = document.documentElement.scrollHeight;
    var remainingScroll = documentHeight - currentScrollBottom;

    if (!visible && remainingScroll < REMAINING_SCROLL_TRIGGER_POINT) {
      showSubscriptionForm();
    } else if (visible && remainingScroll > REMAINING_SCROLL_TRIGGER_POINT) {
      hideSubscriptionForm();
    }
  };

  var debouncedOnScroll = rafThrottle(onScroll, 50);
  document.addEventListener('scroll', debouncedOnScroll);
  var closeButton = document.getElementById('subscribe-close-button');
  closeButton.addEventListener('click', function () {
    hideSubscriptionForm();
    setTimeout(function () {
      subscriptionSectionNode.classList.add('closed');
    }, 300);
  });
});